<template>
  <div class="inform">
    <div class="inform_top">
      <div class="left">
        <img :src="cancel" @click="toBack" alt />
      </div>
      <div class="center">接送管理</div>
    </div>
    <div class="select">
      <div class="select_in">
        <!-- <div @click="showDate = true">
          时间：2023-11-29
          <van-icon name="arrow-down" />
        </div>-->
        <div @click="showClass = true">
          班级：{{className}}
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <ul class="claas_list">
      <li v-for="(item,index) in studentsList" :key="index" @click="toPage('PickRecords',item)">
        <div>
          <img :src="item.gender===1 ? u15 : u16" alt />
        </div>
        <div>{{item.name}}</div>
      </li>
    </ul>

    <!-- <van-popup v-model="showDate" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      />
    </van-popup>-->
    <van-popup v-model="showClass" round position="bottom">
      <van-picker
        show-toolbar
        title="选择班级"
        :columns="columns"
        @cancel="showClass = false"
        @confirm="classConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import right from "@/assets/right.png";
import { getAllClassesListAPI, getStudentsListAPI } from "@/api/pick-up";
import u16 from "@/assets/u16.png";
import u15 from "@/assets/u15.png";
export default {
  data() {
    return {
      cancel,
      u16,
      u15,
      right,
      status: "",
      id: "",
      showClass: [],
      //   minDate: new Date(2020, 0, 1),
      //   maxDate: new Date(2025, 10, 1),
      //   currentDate: new Date(2021, 0, 17),
      //   showDate: false,
      showClass: false,
      user: {},
      classList: [],
      columns: [],
      classId: "",
      studentsList: [],
      className: "",
    };
  },
  created() {
    this.getClassListFn();
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    toPage(name, item) {
      this.$router.push({
        name,
        query: {
          userId: JSON.stringify(item.id),
          classId: JSON.stringify(this.classId),
        },
      });
    },
    classConfirm(value, index) {
      console.log(value, index);
      this.getStudentsListFn(this.classList[index]);
      this.showClass = false;
    },
    getClassListFn() {
      getAllClassesListAPI().then((res) => {
        if (res.code === 0) {
          this.classList = res.data;
          this.classList.map((item) => {
            this.columns.push(item.name);
          });
          this.getStudentsListFn(this.classList[0]);
        }
      });
    },
    getStudentsListFn(item) {
      this.classId = item.id;
      this.className = item.name;
      getStudentsListAPI(item.id).then((res) => {
        if (res.code === 0) {
          this.studentsList = res.data;
        }
      });
    },
    toBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang='scss' scoped>
.inform {
  .inform_top {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    align-items: center;
    border-bottom: 1px solid #ccc;
    background: rgba(241, 241, 241);
    z-index: 999;
    .left {
      position: absolute;
      left: 1rem;
      img {
        // width: 35px;
        height: 30px;
      }
    }
  }
  .select {
    position: fixed;
    top: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #222222;
    background: rgba(241, 241, 241);
    width: 100%;
    z-index: 999;
    .select_in {
      height: 40px;
      background: #ffffff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        margin-right: 2rem;
      }
      div:first-child {
        margin-left: 10px;
      }
    }
  }
  .claas_list {
    width: 100%;
    background: #ffffff;
    padding: 98px 0 4px 0;
    padding-top: 96px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #444;
    font-size: 0.9rem;
    li {
      width: calc(25% + 1px);
      height: 90px;
      text-align: center;
      padding: 10px 0;
      border: 1px solid #dedede;
      box-sizing: border-box;
      /*边框合并*/
      margin: 0 0 -1px -1px;
      position: relative;
      z-index: 0;
      div {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>